.App {
  text-align: center;
  background-color:'#EFEFEF'
}

body {
  background-color:'#EFEFEF'
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.file-wrapper {
  background-color: #fff;
  border-radius: 1rem;
  margin: 0 auto;
  max-width: 500px;
  padding: 2rem;
  width: 100%;
}

.file {
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
}

.file-input label {
  position: relative;
  height: 176px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #bcbcbc;
  font-weight: bold;
  cursor: pointer;
  transition: transform .2s ease-out;
  border: 1px dashed #5F56A6;
box-sizing: border-box;
border-radius: 8px;
}

.file-input-column {
  width: 180px;
  margin: 0px auto;
}
.file-input-column label{
  position: relative;
  height: 176px;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #bcbcbc;
  font-weight: bold;
  cursor: pointer;
  transition: transform .2s ease-out;
  border: 1px dashed #5F56A6;
  box-sizing: border-box;
  border-radius: 8px;
}

.MuiFormControl-root {
  width: 100%;
}

.noDecorate {
  text-decoration: none;
}

@media (min-width: 600px) {
  .MuiTab-root {
    min-width: 30px !important;
}
}

.otp-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    margin-top: 40px;
}

.otp-input {
  width: 40px;
  margin-right: 20px;
}

.otp-input input {
  width: 100% !important;
  border: none;
  border-bottom: 1px solid #d1d1d1;
}

.otp-input input:focus{
  outline: none;
}

.info-alert {
  width: 340px;
  margin: 0px auto;
  top: 76px;
  position: absolute;
  left: calc(50% - 170px);
}

.info-alert .MuiAlert-icon {
  display: none;
}

.textLink {
  font-size: 16px;
}

.textLink span {
  text-decoration: underline;
  color: #0486E2
}

.no-decoration {
  text-decoration: none;
}

.f-14 {
  font-size: 14px;
}
.f-12 {
  font-size: 12px;
}

.c-grey {
  color: #686868;
}

.mb-4 {
  margin-bottom: 4px;
}

.mt-0 {
  margin-top: 0px;
}

.closeAppIcon {
  font-size: 37px !important;
  cursor: pointer
}

.loader {
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0px;
  top: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  flex-direction: column;
  background: #e5e5e5;
  opacity: 0.8;
}

.b-link {
  cursor: pointer;
}

.box-divider {
  margin: 16px 0px !important;
}

.pointer {
  cursor: pointer
}

.flex {
  display: flex;
}

.item-center {
  align-items: center;
}

.c-red {
  color: red;
}

.pt-10 {
  padding-top: 10px;
}
